<template>
  <b-card>

    <!-- form -->
    <validation-observer ref="modelsCreate">
      <b-form>
        <b-row>
          <b-col md="12">
            <div class="section-block mb-4">
              <div class="section-block__title">
                <feather-icon
                  icon="MonitorIcon"
                  width="24"
                  height="24"
                />
                <h2>DHCP Reservation Details</h2>
              </div>
              <b-form-group
                label="DHCP Server"
                label-class="label-class"
                class="neighbouring-form-group"
              >
                <validation-provider
                  #default="{ errors }"
                  name="DHCP Server"
                  rules="required|max:255"
                  vid="server"
                >
                  <v-select
                    v-model="network"
                    :clearable="false"
                    label="name"
                    placeholder="Select available DHCP server"
                    :options="networks"
                    is-open
                    open
                    return-object
                    filled
                    :searchable="true"
                    @search="onSearchNetwork"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <div
                class="neighbouring-form-container"
              >
                <b-form-group
                  label="MAC Address"
                  label-class="label-class"
                  class="neighbouring-form-group"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="MAC Address"
                    rules="max:255"
                    vid="mac_address"
                  >
                    <b-form-input
                      v-model="form.mac_address"
                      :state="errors.length > 0 ? false:null"
                      placeholder="Enter or select available MAC address"
                      autocomplete="off"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
                <b-form-group
                  label="IP Address"
                  label-class="label-class"
                  class="neighbouring-form-group"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="IP Address"
                    rules="max:255"
                    vid="address"
                  >
                    <b-form-ip
                      :errors="errors"
                      placeholder="Enter or select IP Address"
                      autocomplete="off"
                      :onchange="(value) => form.address = value"
                      :model="form.address"
                      inputs-id="address"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </div>
              <b-form-group
                label="Host name"
                label-class="label-class"
                class="neighbouring-form-group"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Host name"
                  rules="max:255"
                  vid="name"
                >
                  <b-form-input
                    v-model="form.name"
                    :state="errors.length > 0 ? false:null"
                    placeholder="Enter host name"
                    autocomplete="off"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <b-form-group
                label="Address list"
                label-class="label-class"
                class="neighbouring-form-group"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Address list"
                  rules="max:255"
                  vid="address_lists"
                >
                  <v-select
                    v-model="form.address_lists"
                    :clearable="false"
                    label="list"
                    placeholder="Select one or more addresses"
                    :options="address_lists"
                    :searchable="true"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <b-form-group
                label="Comment"
                label-class="label-class"
                class="neighbouring-form-group"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Comment"
                  rules="max:255"
                  vid="comment"
                >
                  <b-form-input
                    v-model="form.comment"
                    :state="errors.length > 0 ? false:null"
                    placeholder="Enter your notes about reservation here"
                    autocomplete="off"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </div>
            <!-- submit button -->
            <b-col
              cols="12"
              class="text-right buttons-container__left"
            >
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                type="button"
                ok-variant="secondary"
                @click.prevent="goBack"
              >
                Cancel
              </b-button>
              <b-button-request
                text="Create"
                :request="validationForm"
              />
            </b-col>
          </b-col></b-row>
      </b-form>
    </validation-observer>
  </b-card>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BFormInput, BFormGroup, BForm, BRow, BCol, BButton, BCard,
} from 'bootstrap-vue'
import { required, max } from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import axios from '../../axios-resolver'
import BFormIp from '../../../../components/BFormIP.vue'
import BButtonRequest from '../../../../components/BButtonRequest.vue'

export default {
  directives: {
    Ripple,
  },
  components: {
    BButtonRequest,
    BCard,
    ValidationProvider,
    ValidationObserver,
    BFormInput,
    BFormGroup,
    BForm,
    BFormIp,
    BRow,
    vSelect,
    BCol,
    BButton,
  },
  data() {
    return {
      siteId: this.$route.params.id,
      form: {
        server: null,
        mac_address: '',
        address: '',
        name: '',
        comment: '',
        address_lists: [],
      },

      network: 'All',
      networks: ['All'],
      searchNetwork: '',
      address_lists: [],

      required,
      max,
    }
  },
  destroyed() {
    document.querySelector('.content-header-title__extra-info').innerHTML = ''
  },
  created() {
    if (this.leaseId) {
      this.fetchLease()
    }
    this.getNetworks()
    this.setSiteName(this.$route.params.id)
  },
  methods: {
    goBack() {
      this.$router.go(-1)
    },
    async setSiteName(id) {
      try {
        if (id) {
          const pageTitle = document.querySelector('.content-header-title__extra-info')
          const { data: { data } } = await axios.get(`/site/${id}/show`)

          const link = document.createElement('a')
          link.href = `/site/${id}/hardware/index`
          link.innerHTML = `(${data.name})`

          pageTitle.innerHTML = ''
          pageTitle.appendChild(link)
        }

        this.isAccessPointUnlicensed = this.summary.isAccessPointUnlicensed // do when the API will be ready
      } catch (error) {
        console.log('error', error)
      }
    },
    async fetchLease() {
      try {
        const { data } = await axios.get(`/site/${this.$route.params.id}/network/active-lease/${this.leaseId}`)
        const { mac_address, name, address } = data.data
        this.form = {
          ...this.form,
          mac_address,
          name,
          address,
          server: this.form.server === 'All' ? 'all' : this.form.server,
        }
      } catch (error) {
        console.error(error)
      }
    },
    async getNetworks() {
      try {
        const { data } = await axios.get(`/site/${this.$route.params.id}/network/active-lease/options`)
        this.networks = ['All', ...data.options.dhcpServers]
        this.address_lists = data.options.addressList
      } catch (error) {
        console.error(error)
      }
    },
    onSearchNetwork(search) {
      this.searchNetwork = search
    },
    onRemoveAddress(value) {
      this.form.address_lists = this.form.address_lists.filter(item => item.id !== value.id)
    },
    async validationForm() {
      const valid = await this.$refs.modelsCreate.validate()

      if (valid) {
        try {
          const payload = {
            ...this.form,
            server: this.network,
            address_lists: this.form.address_lists,
          }
          await axios.post(`/site/${this.$route.params.id}/network/active-lease/`, payload)

          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Success!',
              icon: 'BellIcon',
              variant: 'success',
            },
          })

          this.goBack()
        } catch (error) {
          const { status } = error.response

          if (status === 422) {
            const { fields } = error.response.data

            if (!fields.length) {
              const { message } = error.response.data

              this.$toast({
                component: ToastificationContent,
                props: {
                  title: message,
                  icon: 'BellIcon',
                  variant: 'danger',
                },
              })
            }

            this.$refs.modelsCreate.setErrors(fields)
          }
        }
      }
    },
  },
}
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

</style>
